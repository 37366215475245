<!-- 环比分析 -->
<template>
  <div id="month-analysis">
    <!-- <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="false"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div> -->
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>环比分析</span>
      </div>
      <div class="content">
        <ul class="tabs">
          <li
            v-for="(item, index) in tabsData"
            :key="index"
            :class="select === index ? 'select' : ''"
            @click="switchTabs(index)"
          >
            {{ item }}
          </li>
        </ul>
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期:" class="query-date">
              <el-date-picker
                :type="timeType"
                v-model="formData.time"
                :append-to-body="false" placeholder="请选择日期" value-format="yyyy-MM-dd" 
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="getList" type="primary" icon="el-icon-search">搜索</el-button>
             
            </el-form-item>
          </el-form>
        </div>

        <el-table class="table" :data="tableData">
          <el-table-column align="center" prop="loopName" label="回路名称">
            <template slot-scope="scope">
              {{ scope.row.loopName }}  ({{ scope.row.loopNumber }})
            </template>
          </el-table-column>
          <el-table-column align="center" prop="nowDianNeng" :label="nowDianNeng">
          </el-table-column>
          <el-table-column align="center" prop="lastDianNeng" :label="lastDianNeng">
          </el-table-column>
          <el-table-column align="center" prop="increaseValue" label="增加值">
            <template slot-scope="scope"> 
              {{ scope.row.increaseValue }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="three" label="环比">
            <template slot-scope="scope">
              <div class="flex-center">
                {{ (scope.row.powerRatio * 100).toFixed(2) }}%
                <img
                  v-if="scope.row.powerRatio <= 0"
                  class="trend"
                  src="../../assets/images/analysis/decline.png"
                  alt=""
                />
                <img
                  v-else
                  class="trend"
                  src="../../assets/images/analysis/rise.png"
                  alt=""
                />
                <!-- <span style="color: rgb(162, 120, 7)">{{
                  scope.row.three
                }}</span> -->
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="viewChart"
                >图表</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
      
      </div>
    </div>
    <el-dialog title="导出" :visible.sync="exportDialog" width="20%">
      <span class="dialog-content">确定要导出数据吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialog = false">取 消</el-button>
        <el-button type="primary" @click="exportDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 折线图 -->
    <el-dialog title="图表" :visible.sync="chartDialog" width="30%">
      <div id="chart"></div>
    </el-dialog>
  </div>
</template>
<script>
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
import * as echarts from "echarts";
export default {
  name: "",

  data() {
    return {
      formData: {
        time: "",
      },
      exportDialog: false,
      chartDialog: false,
      tabsData: ["日报", "周报",  '月报' ],
      nowDianNeng:'当日用电/kWh',
      lastDianNeng:'上日用电/kWh',
      tableData: [],
      select: 0,
      timeType:'date',
      stationNumber:'',
      staTime:'',
      endTime:'',
     
    };
  },
  created(){
    this.$store.commit('increment', '用电分析')
    this.$store.commit('selectChild', '环比分析')
    this.$store.commit("selectChildren", "");
},

  mounted() {

    // this.queryOperation();
    this.stationNumber=sessionStorage.getItem('stationNumber')
    this.getList()
  },

  methods: {
   
    viewChart() {
      this.chartDialog = true;
      this.$nextTick(() => {
        this.barChart();
      });
    },
    barChart() {
      var chart = echarts.init(document.getElementById("chart"));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "增长值:11.16kWh  增长率:43.58%",
          left: "center",
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          show: true,
          bottom: "1%",
          left: "center",
          textStyle: {
            color: "#fff",
          },
        },
        color: ["#14bf9b", "#176ad2"],
        xAxis: {
          data: ["主进线柜"],
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 12,
            },
          },
        },
        yAxis: {
          name: "单位:km/h",
          nameTextStyle: {
            color: "#aed6ff",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "当日用电",
            type: "bar",
            barWidth: 10,
            data: [10],
          },
          {
            name: "上日用电",
            type: "bar",
            barWidth: 10,
            data: [20],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option);
    },
    switchTabs(index) {
      this.select = index;
      this.formData.time=''
      if(index==0){
        this.timeType='date'
        this.nowDianNeng='当日用电/kWh'
        this.lastDianNeng='上日用电/kWh'
      }
      if(index==1){
        this.timeType='date'
        this.nowDianNeng='本周用电/kWh'
        this.lastDianNeng='上周用电/kWh'
      }
      if(index==2){
        this.nowDianNeng='本月用电/kWh'
        this.lastDianNeng='上月用电/kWh'
        this.timeType='month'
      }
      this.getList()
    },
    getList() {
      let type;
      if(this.select==0){
        this.getDayStartAndEnd()
        type=1
      }else if (this.select==1){
        this.getWeekRange(this.formData.time)
        type=2
      }else {
        this.getMonthStartAndEnd(this.formData.time)
        type=3

      }
      let obj={
        stationNumber:this.stationNumber,
        staTime:this.staTime,
        endTime:this.endTime,
        type:type
      }
      emissionsCarbonApi.analysisTwoApi(obj).then(res=>{
        console.log(res);
        if(res.code==200){
          this.tableData=res.data
        }
      })
      
    },
    getDayStartAndEnd(){
      const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要加 1；使用 padStart 补齐两位
    const day = String(today.getDate()).padStart(2, '0'); // 使用 padStart 补齐两位
    let sta;
    let end;
    if(this.formData.time){
      
      this.staTime = `${this.formData.time}` ;
      this.endTime= `${this.formData.time}`;
    }else{
      this.staTime = `${year}-${month}-${day}`;
      this.endTime= `${year}-${month}-${day}`;
    }
    console.log('````',this.staTime,this.endTime);
    },
    getMonthStartAndEnd(dateString) {
      let date;
    if (dateString) {
        date = new Date(dateString);
    } else {
        date = new Date();
    }
    
    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const firstDay = new Date(year, month - 1, 2); 
    const lastDay = new Date(year, month, 1); 

    const formatDate = (d) => {
        return d.toISOString().slice(0, 10);
    };
    this.staTime = formatDate(firstDay);
    this.endTime= formatDate(lastDay);
    console.log(this.staTime,this.endTime);
   
    },
    //year
    getWeekRange(inputDate) {
    var currentDate = inputDate ? new Date(inputDate) : new Date();
    var day = currentDate.getDay(); // 获取星期几，0 表示星期日，1 表示星期一，以此类推
    var startDate = new Date(currentDate); // 复制当前日期对象
    var endDate = new Date(currentDate); // 复制当前日期对象
    startDate.setDate(currentDate.getDate() - day + (day === 0 ? -6 : 1));

    endDate.setDate(currentDate.getDate() - day + 7);

    var format = function (date) {
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var day = ('0' + date.getDate()).slice(-2);
        return year + '-' + month + '-' + day;
    };
      this.staTime= format(startDate),
      this.endTime= format(endDate)
}
    
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#month-analysis {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}
/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}
.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}
::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}
::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}
::v-deep.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #03183f;
}
.main {
  width: 100%;
  min-width: 1280px;
  padding: 20px;
  box-sizing: border-box;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}
/* tabs */
.tabs {
  width: 100%;
  background-color: rgb(4, 25, 66);
  display: flex;
}
.tabs li {
  width: 85px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
}
.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}
/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}
.query-form :deep() .el-form {
  height: 40px;
}
.form :deep() .el-icon-date {
  color: #aed6ff;
}
.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}
.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}
.hour :deep() .el-select {
  width: 56px;
  padding: 0;
}
.hour span {
  color: #aed6ff;
  margin: 0 5px 0 8px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
}
/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}
/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination :deep() .el-pagination {
  padding-left: 0;
}
.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}
.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}
.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination >>> .btn-prev,
.el-pagination >>> .btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.el-pagination >>> .el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}
.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  color: #92b5da;
  margin: 0 10px;
}
.pagination-input >>> .el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
}
.pagination :deep().el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}
.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}
.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}
/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}
.trend {
  width: 16px;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
#chart {
  width: 100%;
  height: 330px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}
.dialog-footer button {
  width: 100px;
  height: 35px;
  background: #2142a1;
  border: 0;
  color: #fff;
}
.dialog-content {
  font-size: 16px;
  color: #ebedf1;
}
::v-deep .el-table__body-wrapper {
  height: 65vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
